import React,{ useState,useEffect} from 'react';
import {
   MDBContainer,
  MDBRow,
  MDBCol,
}
from 'mdb-react-ui-kit';
import Bannerlogo from '../Assets/Visitor-Registration-Banner.png';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


 function VisiterRegistration() {

  //const createUserApi = "http://localhost/eventapi/index.php/auth/visitorDataSave"
  //const createUserApi = "http://localhost:3002/visitorregistration"
  const createUserApi = `${process.env.REACT_APP_API_URL}auth/visitorDataSave`
  const navigate = useNavigate();
    const [error, setError] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    

    useEffect(()=>{
      document.title="Visitor Registration - Auto EV Expo";
    },[]);
  

    // name, jobprofile, companyname,phone,email,city,referby,message
    const [user, setUser] = useState({
        name: "",
        jobprofile: "",
        companyname: "",
        phone: "",
        email: "",
        city: "",
        referby: "",
        message: ""
    });

    const handelInput = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      console.log(name, value)
      setUser({ ...user, [name]: value });
  }


  const validateForm = () => {
    let isValid = true;
    const newErrors = [];

    // Validate password
    if (!user.name) {
      newErrors.name = "Full Name is required";
      isValid = false;
    }
    if (!user.jobprofile) {
      newErrors.jobprofile = "Job Profile is required";
      isValid = false;
    }
    if (!user.companyname) {
      newErrors.companyname = "Company Name is required";
      isValid = false;
    }
    if (!user.phone) {
      newErrors.phone = "Mobile Number is required";
      isValid = false;
    }
    if (user.phone.length!=10) {
      newErrors.phone = "Mobile Number is inValid";
      isValid = false;
    }
    if (!user.email) {
      newErrors.email = "Email Address is required";
      isValid = false;
    }
    if (!user.city) {
      newErrors.city = "City Name is required";
      isValid = false;
    }
    if (!user.referby) {
      newErrors.referby = "Refrence By is required";
      isValid = false;
    }
   

    setError(newErrors);
    return isValid;
  };


  const handelSubmit = async (event) => {
    event.preventDefault();
    if(!validateForm())
    {
      
      //console.log(error);
    }
    else{
    //console.log(user)
    try {
        setIsClicked(true);
        const response = await fetch(createUserApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
           const data = await response.json();
           setIsClicked(false);
           console.log(response);
           console.log(data.results);
           console.log(data.results.status)
           console.log(data.results.message);

           // alert("Thank you for Registration");
            console.log('Form submitted successfully!');
            setUser({name: "",
            jobprofile: "",
            companyname: "",
            phone: "",
            email: "",
            city: "",
            referby: "",
            message: ""})
            if(data.results.status=='alreadyexists')
              {
                setIsClicked(true);
                
                document.getElementById('viewloading').innerHTML='';
                document.getElementById('msg').innerHTML=data.results.message+data.userlink;
              }
              else{

            navigate('/Visiterthankyou');
              }
        } else {
            console.error('Form submission failed!');
        }

    } catch (error) {
        setError(error.message);
    } finally{
        
    }
  }
}



  return (
    <div>
       <Header />
   
    <div className='container'>
      <div className='row'>
        
    <MDBContainer className="my-5 gradient-form">
      
      <MDBRow>
      <div className='col-md-7'>
        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column ms-5">


          <div className='user-form'>
            <div className='heading'>
          
            {/* {error && <p>Error: {error}</p>} */}
            <h1>Visitor Registration</h1>
            </div>
            <form onSubmit={handelSubmit}>
            <div className='shadow-sm p-3 mb-5 bg-light rounded mr-bottom '>
                <div className="mb-3">
                    <label for="name" className="form-label required">Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={user.name}   onChange={handelInput} />
                    {error.name && <div className="error">{error.name}</div>}
                </div>
                <div className="mb-3 mt-3">
                    <label for="jobprofile" className="form-label required">Job Profile</label>
                    <input type="text" className="form-control" id="jobprofile" name="jobprofile" value={user.jobprofile}  onChange={handelInput} />
                    {error.jobprofile && <div className="error">{error.jobprofile}</div>}
                </div>
                <div className="mb-3">
                    <label for="companyname" className="form-label required">Company Name</label>
                    <input type="text" className="form-control" id="companyname" name="companyname" value={user.companyname}   onChange={handelInput} />
                    {error.companyname && <div className="error">{error.companyname}</div>}
                </div>

                <div className="mb-3">
                    <label for="phone" className="form-label required">Phone</label>
                    <input type="text" className="form-control" id="phone" name="phone" value={user.phone}   onChange={handelInput} />
                    {error.phone && <div className="error">{error.phone}</div>}
                </div>
                <div className="mb-3">
                    <label for="email" className="form-label required">Email Address</label>
                    <input type="email" className="form-control" id="email" name="email" value={user.email}  onChange={handelInput} />
                    {error.email && <div className="error">{error.email}</div>}
                </div>

                

                <div className="mb-3">
                    <label for="city" className="form-label required">City Name</label>
                    <input type="text" className="form-control" id="city" name="city" value={user.city}  onChange={handelInput} />
                    {error.city && <div className="error">{error.city}</div>}
                </div>

                <div className="mb-3">
                    <label for="referby" className="form-label required">Refrence By</label>
                    <select className="form-control form-select" id="referby" name="referby"   onChange={handelInput}>
                    <option value="">--Select--</option>
                     <option value="Google">Google</option>          
                     <option value="Linked-In">Linked-In</option>
                     <option value="Facebook">Facebook </option>
                     <option value="Magazine/Newspaper">Magazine/Newspaper</option>
                     <option value="Industry Colleague">Industry Colleague</option>
                     <option value="Email">Email</option>
                     <option value="Visited Past Show">Visited Past Show</option>
                     <option value="Website ADs">Website ADs</option>
                     <option value="Outdoor Promotion">Outdoor Promotion</option>
                     <option value="Other">Other</option>
                    </select>
                    {error.referby && <div className="error">{error.referby}</div>}
                    {/* <input type="text" className="form-control" id="referby" name="referby"  onChange={handelInput} /> */}
                </div>

                <div className="mb-3">
                    <label for="message" className="form-label">Message</label>
                    <textarea type="text" className="form-control" id="message" value={user.message} name="message" onChange={handelInput}></textarea>
                    {error.message && <div className="error">{error.message}</div>}
                </div>
                <div className="termssmall">By submitting this form, you hereby grant us permission to contact you via SMS, WhatsApp, RCS, Email, and any other channel.</div>
                <button type="submit" disabled={isClicked} className="btn btn-primary submit-btn">Submit</button>
                {isClicked?<>
                <div><h3 id="viewloading">Loading....</h3></div></>:''}
                <div id="msg" className="msg"></div>
                </div>
            </form>
        </div>

          </div>



        </MDBCol>
        </div>
        <div className='col-md-5'>
       
        <MDBCol col='6' className="mb-5">
          <div className="d-flex flex-column  justify-content-center  h-100 mb-4">
           <div className="rigthbanner">
             <br/>
             <img src={Bannerlogo}/>
             
             </div>

          </div>

        </MDBCol>
        </div>

      </MDBRow>

    </MDBContainer> 
        </div>
       </div>
       

    <div className='container-fluid bg-green mr-200'>
            <Footer />
            </div>
    </div>
  )
}

export default VisiterRegistration;