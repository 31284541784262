import React from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

function Visiterthankyou() {
  return (
    <div>
      <Header/>
    <div className='container'>
    <div className='row'>
      <div className='shadow-sm p-10 mb-5 mt-5 bg-light rounded mr-bottom '>
        <p>Dear Visitor,</p>
        <p>Thank you for registering for Auto EV India 2024 Bengaluru. You will receive a confirmation E Mail with your registration details and Visitor Badge in it shortly. </p>
        <p>We look forward to welcoming you to the Auto EV India  from 27 - 29 November 2024 at KTPO, Bengaluru.</p>
        <p>Should you need any other information, please <a target='_blank' href="https://www.autoevexpo.com/">contact us</a>.</p>
        <Link className="nav-link" to="/VisitorRegistration">Back</Link>
        </div>
     </div>
    <Footer/>
    </div>
    </div>
  )
}

export default Visiterthankyou