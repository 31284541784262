import {React,useEffect,useState} from 'react'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import { useLocation, useNavigate } from 'react-router-dom'

export const PaymentSuccess = () => {

  const createUserApi = `${process.env.REACT_APP_API_URL}auth/conferenceDataUpdate`

  const location=useLocation();
  
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const searchParamms=new URLSearchParams(location.search);

  let paymentintentValue=searchParamms.get('payment_intent');
  let paymentClientsecret=searchParamms.get('payment_intent_client_secret');
  let paymentStatus=searchParamms.get('redirect_status');
  const bookingid=localStorage.getItem('bookingid');

  const user={ 
    paymntid: paymentintentValue,
    clintid: paymentClientsecret,
    paystatus: paymentStatus,
    bookingid: bookingid,
};

console.log(user)




  //useEffect(()=>{

  async function fetchData() 
  {
  try {
    const response= await fetch(createUserApi, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    });

    if (response.ok) {
        localStorage.removeItem('bookingid'); 
        
    } else {
        console.error('Form submission failed!');
    }

} catch (error) {
    setError(error.message);
} finally {

}
    }

    fetchData();
    navigate('/Login');


  return (

    <div>
            <Header />
            <div className="container-fluid paymentbody">
              <div className="row  mrpayment-200">
              <div className="col-md-12 shadow-sm p-3 mb-5 bg-light rounded">
                 <p>Thank you for registering for Auto EV TECH-VISION Summit 2024 Bengaluru. You will receive a confirmation E Mail with your registration details and Conference Badge in it shortly. </p>
                 <p>We look forward to welcoming you to the Auto EV TECH-VISION Summit 2024 from 27 - 28 November 2024 at KTPO, Bengaluru.</p>
                 <p>Should you need any other information, please <a target="_blank" href="https://www.autoevexpo.com/">contact us</a>.</p>
                 </div>
            </div></div>
            <Footer />
            </div>
  )
}
